module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"James Neill","short_name":"James Neill","start_url":"/","background_color":"#ffffff","theme_color":"#48c774","display":"minimal-ui","icon":"icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bfb02e7696997bbbe1fc3a40825dfacd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-goatcounter/gatsby-browser.js'),
      options: {"plugins":[],"code":"jamesneill","exclude":[],"pageTransitionDelay":0,"head":false,"pixel":true,"allowLocal":false,"localStorageKey":"skipgc","referrer":false,"urlCleanup":false},
    }]
